import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Inner from '../../components/templates/Inner';
import { Divider, Box, Flex, Text } from '../../components/atoms';
import FootNote from '../../components/molecules/FootNote';
import img01 from '../../assets/img/design-tips/components/01.png';
import img02 from '../../assets/img/design-tips/components/02.png';
import img03 from '../../assets/img/design-tips/components/03.png';
import img04 from '../../assets/img/design-tips/components/04.png';
import img05 from '../../assets/img/design-tips/components/05.png';
import img06 from '../../assets/img/design-tips/components/06.png';
import img07 from '../../assets/img/design-tips/components/07.png';
import img08 from '../../assets/img/design-tips/components/08.png';
import img09 from '../../assets/img/design-tips/components/09.png';
import img10 from '../../assets/img/design-tips/components/10.png';
import img11 from '../../assets/img/design-tips/components/11.png';
import img12 from '../../assets/img/design-tips/components/12.png';
import img13 from '../../assets/img/design-tips/components/13.png';
import img14 from '../../assets/img/design-tips/components/14.png';
import img15 from '../../assets/img/design-tips/components/15.png';
import { designTips as nav } from '../../nav';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <Inner nav={nav} title="Components" mdxType="Inner">
      <h3>{`Components`}</h3>
  <Text maxWidth={560} mdxType="Text">
    The components are our LEGO blocs. They bring composition, inheritance and
    unlimited overrides from engineering to design.
  </Text>
  <Divider my={64} mdxType="Divider" />
      <h4>{`Define bricks then build houses`}</h4>
  <Text maxWidth={560} mdxType="Text">
    Generally, components consist of lower-level elements: icons, forms, buttons,
    headlines, paragraphs, etc. The goal is to preserve consistency in appearance
    and to reuse them throughout the app.
  </Text>
  <Flex my="xxl" mdxType="Flex">
    <Box width={400} mr={104} mdxType="Box">
      <Box maxWidth={400} ml={-15} mdxType="Box"><img src={img01} alt="" className="img-fluid" /></Box>
      <Box maxWidth="368px" mdxType="Box"><img src={img03} alt="" className="img-fluid" /></Box>
    </Box>
    <Box mdxType="Box">
      <Box maxWidth={400} ml={-30} mdxType="Box"><img src={img02} alt="" className="img-fluid" /></Box>
      <FootNote status="bad" mt={-10} mdxType="FootNote" />
      <Text fontSize="m" mdxType="Text">Card look changed, DRY princinple broken.</Text>
      <Text fontSize="m" mdxType="Text">1. New borders around the card.<br />
        2. Different edges rounding.<br />
        3. Different shadow style.
      </Text>
    </Box>
  </Flex>
  <Divider m="64px 0" mdxType="Divider" />
      <h4>{`Keep density consistent`}</h4>
      <p>{`  Keep consistent density to preserve a unified and balanced component look.`}</p>
  <Flex my="xxl" mdxType="Flex">
    <Box width={420} mr={104} mdxType="Box">
      <Box mdxType="Box"><img src={img04} alt="" className="img-fluid" /></Box>
      <Box fontSize="m" m="-30px 0 0 48px" mdxType="Box">
        <FootNote status="good" mdxType="FootNote" />
        Consistent distances: 32px outside the card and 24px between the elements.
      </Box>
    </Box>
    <Box width={420} mdxType="Box">
      <Box mdxType="Box"><img src={img05} alt="" className="img-fluid" /></Box>
    </Box>
  </Flex>
  <Flex m="72px 0 48px" mdxType="Flex">
    <Box width={420} mr={104} mdxType="Box">
      <Box mdxType="Box"><img src={img06} alt="" className="img-fluid" /></Box>
      <Box fontSize="m" mb="xxl" mdxType="Box">
        <FootNote status="bad" mdxType="FootNote" />
        Different distance around the elements. Disrupted balance.
      </Box>
    </Box>
    <Box width={400} mdxType="Box">
      <Box mdxType="Box"><img src={img07} alt="" className="img-fluid" /></Box>
    </Box>
  </Flex>
  <Divider my={64} mdxType="Divider" />
      <h4>{`Follow best practices`}</h4>
  <Text maxWidth={560} mdxType="Text">
    Navigation blocks must be available in the places where the user expects to see them. Have a better idea? Prove it with A/B test.
  </Text>
  <Flex my="xxl" mdxType="Flex">
    <Box width={360} mr={144} mdxType="Box">
      <Box mdxType="Box"><img src={img08} alt="" className="img-fluid" /></Box>
      <FootNote status="bad" mdxType="FootNote" />
      <Box fontSize="m" mdxType="Box">Navigation hidden between content.</Box>
    </Box>
    <Box width={360} mdxType="Box">
      <Box mdxType="Box"><img src={img09} alt="" className="img-fluid" /></Box>
      <FootNote status="bad" mdxType="FootNote" />
      <Box fontSize="m" mdxType="Box">Navigation overloaded with unnecessary functionality.</Box>
    </Box>
  </Flex>
  <Flex my="xxl" mdxType="Flex">
    <Box width={400} mr={104} mdxType="Box">
      <Box mdxType="Box"><img src={img11} alt="" className="img-fluid" /></Box>
      <FootNote status="good" mdxType="FootNote" />
      <Box fontSize="m" mdxType="Box">Main actions are accessible. No extra clicks.</Box>
    </Box>
    <Box width={400} mdxType="Box">
      <Box mdxType="Box"><img src={img10} alt="" className="img-fluid" /></Box>
      <FootNote status="bad" mdxType="FootNote" />
      <Box fontSize="m" mdxType="Box">Main actions hidden in menu.</Box>
    </Box>
  </Flex>
  <Divider my={64} mdxType="Divider" />
      <h4>{`More space for click`}</h4>
  <Text maxWidth={560} mdxType="Text">
    Aside from the visual part, control elements must include a more significant
    clickable area. We will use it for Tap, Click, Active events.
  </Text>
  <Flex my="xxl" mdxType="Flex">
    <Box width={360} mr={104} mdxType="Box">
      <Box mdxType="Box"><img src={img13} alt="" className="img-fluid" /></Box>
      <FootNote status="good" mdxType="FootNote" />
      <Box fontSize="m" mdxType="Box">
        Create extra borders to increase the clickable area.
        Here we use 8px around the icon.
      </Box>
    </Box>
    <Box width={360} mdxType="Box">
      <Box mdxType="Box"><img src={img12} alt="" className="img-fluid" /></Box>
      <FootNote status="bad" mdxType="FootNote" />
      <Box fontSize="m" mdxType="Box">
        Small clickable area for Mr. Precise Clicker. Will probably
        make regular user upset.
      </Box>
    </Box>
  </Flex>
  <Flex mt="xxl" mdxType="Flex">
    <Box width={360} mr={104} mdxType="Box">
      <Box mdxType="Box"><img src={img15} alt="" className="img-fluid" /></Box>
      <FootNote status="good" mdxType="FootNote" />
      <Box fontSize="m" mdxType="Box">Following the rule on 🔝</Box>
    </Box>
    <Box width={360} mdxType="Box">
      <Box mdxType="Box"><img src={img14} alt="" className="img-fluid" /></Box>
      <FootNote status="bad" mdxType="FootNote" />
      <Box fontSize="m" mdxType="Box">No extra area for the action. </Box>
    </Box>
  </Flex>
    </Inner>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      